import {
  Button,
  Container,
  Flex,
  Grid,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { BackButton, DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import type { ProductCategoryResponseDto, ServiceProviderResponseDto } from '@wanda-space/types'
import { FormLabelMaxChars } from 'components/Forms/FormLabelMaxChars'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown'

import type { OrderLineWithFullProductAndDiscount, Product } from '../../../api-client'
import { sanitizeStripeAmount } from '../../../utils'
import { reactMarkdownToChakraMapper } from '../../../utils/markdown'

export type AddServiceModalData =
  | {
      orderLine: OrderLineWithFullProductAndDiscount
      orderLineIndex?: number
      storageProduct?: Product
      creatingNew: boolean
      isOpen: true
    }
  | { isOpen: false }

interface props {
  onClose: () => void
  onAdd: (comment: string) => void
  onRemove: (index: number) => void
  data: AddServiceModalData
  serviceProvider: ServiceProviderResponseDto
  productCategory: ProductCategoryResponseDto
}

export const AddServiceModal = ({
  onClose,
  onAdd,
  onRemove,
  data,
  serviceProvider,
  productCategory,
}: props) => {
  const [comment, setComment] = useState<string>('')
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (!data.isOpen) {
      setComment('')
    } else {
      if (!data.creatingNew) {
        setComment(data.orderLine.comment ?? '')
      }
    }
  }, [data.isOpen])

  if (!data.isOpen) {
    return null
  }

  return (
    <Modal isOpen={data.isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent containerProps={{ maxH: '100%' }}>
        <Container>
          <BackButton
            linkProps={{
              onClick: (e) => {
                e.preventDefault()
                onClose()
              },
            }}
          />
          <Text mb={4} fontWeight="medium" fontSize="xl" mt={4}>
            {formatMessage({ id: data.creatingNew ? 'word.add' : 'word.edit' })}
          </Text>
          <Flex
            width="100%"
            height={40}
            borderRadius="8px"
            backgroundColor="purple.400"
            alignItems="center"
            justifyContent="center"
            mb={4}
          >
            <DynamicIcon
              iconName={(productCategory.iconName ?? DynamicIconName.tools) as DynamicIconName}
              height={16}
              width={16}
              color="white"
            />
          </Flex>
          <Flex direction="column">
            <Text fontWeight="medium"> {data.orderLine?.item?.name}</Text>
            <Text mb={4} fontSize="3xl" fontWeight="400">
              {formatMessage({ id: `${data.orderLine?.product?.localizationKey}.name` })}
            </Text>
          </Flex>
          <ReactMarkdown components={reactMarkdownToChakraMapper()} skipHtml={true}>
            {formatMessage(
              { id: `${data.orderLine?.product?.localizationKey}.moreInfo` },
              {
                price: sanitizeStripeAmount(data.orderLine?.product.price),
              }
            )}
          </ReactMarkdown>
          <Grid
            backgroundColor="gray.100"
            gridTemplateColumns="repeat(3, 1fr)"
            borderRadius="2xl"
            mb={4}
          >
            <Flex direction="column" px={4} py={2}>
              <Text fontWeight="400" color="gray.600">
                {formatMessage({ id: 'word.price' })}
              </Text>
              <Text fontWeight="medium">
                {sanitizeStripeAmount(data.orderLine?.product.price)},-
              </Text>
            </Flex>
            <Flex direction="column" justifyContent="left" px={4} py={2}>
              <Text fontWeight="400" color="gray.600">
                {formatMessage({ id: 'phrase.service.etaServiceTime' })}
              </Text>
              <Text fontWeight="medium">{data.orderLine?.product.metadata.leadTime ?? 'N/A'} </Text>
            </Flex>
            <Flex direction="column" justifyContent="center" px={4} py={2}>
              <Text fontWeight="400" color="gray.600">
                {formatMessage({ id: 'phrase.service.performedBy' })}
              </Text>
              <Text fontWeight="medium">{serviceProvider.name}</Text>
            </Flex>
          </Grid>
          <Text fontWeight="medium">{formatMessage({ id: 'phrase.writeAComment' })}</Text>
          <ReactMarkdown components={reactMarkdownToChakraMapper()} skipHtml={true}>
            {formatMessage({ id: `${productCategory.localizationKey}.service.commentHelp` })}
          </ReactMarkdown>
          <Textarea
            isInvalid={comment.length > 300}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            data-testid="service-product-comment"
          />
          <FormLabelMaxChars maxCharacters={300} />
          {!data?.creatingNew && typeof data.orderLineIndex === 'number' && (
            <Button
              data-testid="remove-button"
              size="lg"
              onClick={() => {
                onRemove(data.orderLineIndex!)
              }}
              width="100%"
              mb={4}
            >
              {formatMessage({ id: 'word.remove' })}
            </Button>
          )}
          <Button
            colorScheme="ctaBlack"
            data-testid="save-button"
            size="lg"
            onClick={() => {
              onAdd(comment)
            }}
            width="100%"
          >
            {formatMessage({ id: data.creatingNew ? 'word.add' : 'word.save' })}
          </Button>
        </Container>
      </ModalContent>
    </Modal>
  )
}
