import {
  Heading,
  type HeadingProps,
  ListItem,
  type ListItemProps,
  type ListProps,
  OrderedList,
  Text,
  type TextProps,
  UnorderedList,
} from '@chakra-ui/react'
import React, { type ComponentType, type ElementType } from 'react'

export const reactMarkdownToChakraMapper = () => {
  const sizes: Record<string, string> = {
    h1: '4xl',
    h2: '3xl',
    h3: '2xl',
    h4: 'xl',
    h5: 'lg',
    h6: 'md',
  }

  const components = {} as Record<string, ComponentType>

  Object.keys(sizes).forEach((size) => {
    components[size] = (props: HeadingProps) => (
      <Heading mb="3" as={size as ElementType} size={sizes[size]} {...props} />
    )
  })

  components.p = (props: TextProps) => <Text mb="3" {...props} />
  components.ul = (props: ListProps) => <UnorderedList mb="3" {...props} />
  components.ol = (props: ListProps) => <OrderedList mb="3" {...props} />
  components.li = (props: ListItemProps) => <ListItem {...props} />

  return components
}
