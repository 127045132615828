import {
  type OrderResponseDto,
  type ProductPriceType,
  ProductType,
  SupportedCountries,
  UserFeatures,
} from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import type { UserAddress } from 'interfaces/user'
import { isEmpty, isNil } from 'ramda'

import { AddressRenderState, type ContactInfoData, FieldNames } from './types'

export const joinWithComma = (arr: string[]) => {
  return arr.join(', ')
}

export const isOrderAddressSameAsUserAddress = (
  userAddress: UserAddress,
  orderAddress: ContactInfoData
) => {
  if (
    userAddress.firstName === orderAddress.firstName &&
    userAddress.lastName === orderAddress.lastName &&
    userAddress.street === orderAddress.street &&
    userAddress.city === orderAddress.city &&
    userAddress.postalCode === orderAddress.postalCode
  ) {
    return true
  }

  return false
}

export const isAddressComplete = (
  userAddress: UserAddress | ContactInfoData,
  hiddenFields: FieldNames[] = []
) => {
  const { firstName, lastName, city, floorNumber, elevator, phoneNumber, street, postalCode } =
    userAddress

  const isPhoneNumberHidden = hiddenFields.includes(FieldNames.PHONE_NUMBER)

  const isComplete =
    firstName &&
    lastName &&
    city &&
    (isPhoneNumberHidden || phoneNumber) &&
    street &&
    postalCode &&
    isNil(floorNumber) === false &&
    isNil(elevator) === false

  return Boolean(isComplete)
}

export const isFlexAddressDisabled = (countryCode: SupportedCountries) => {
  const flexDisabledCountries = [SupportedCountries.SE, SupportedCountries.NO]
  return flexDisabledCountries.includes(countryCode)
}

export const hasOrderContactInfo = (contactInfo: ContactInfoData) =>
  isNil(contactInfo) === false && isEmpty(contactInfo) === false

export const showContactCardCheck = (addressRenderState: AddressRenderState) => {
  let showContactCard = true
  if (
    addressRenderState === AddressRenderState.ORDER_ADDRESS_ACTIVE ||
    addressRenderState === AddressRenderState.USER_DEFAULT_ADDRESS_INCOMPLETE ||
    addressRenderState === AddressRenderState.CHANGE_ORDER_ADDRESS
  ) {
    showContactCard = false
  }

  return showContactCard
}

export const hasFlexAddressPredicate = (type: ProductPriceType) => {
  return (
    orderline: OrderLineWithFullProductAndDiscount | OrderResponseDto['orderLines'][number]
  ) => {
    return (
      orderline.product.productType === ProductType.USER_FEATURE &&
      orderline.product.priceType === type &&
      orderline.product.metadata?.userFeatureKey === UserFeatures.FLEX_ADDRESS
    )
  }
}

export const isFieldVisible = (field: FieldNames, hideList?: FieldNames[]) => {
  if (typeof hideList === 'undefined') {
    return true
  }

  const isHidden = hideList.includes(field)

  return !isHidden
}

export const isFieldDisabled = (field: FieldNames, disableList?: FieldNames[]) => {
  if (typeof disableList === 'undefined') {
    return false
  }

  const isDisabled = disableList.includes(field)

  return isDisabled
}

export const isContactInformationComplete = (address?: UserAddress | ContactInfoData) => {
  const { firstName, lastName, phoneNumber } = address ?? {}

  const isComplete = firstName && lastName && phoneNumber

  return Boolean(isComplete)
}
