import { Card, Flex, Text } from '@chakra-ui/react'
import { MoneyInHandIcon } from '@wanda-space/noelle'
import { Routes } from 'consts'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'

export const ReferFriendCard = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const { data: featureFlags } = useFeatureFlags()

  if (!featureFlags?.ENABLE_REFERRAL) {
    return null
  }

  return (
    <Card
      borderRadius={10}
      bgColor={'brown.100'}
      p={4}
      mt={10}
      border={'solid'}
      borderColor={'ctaBlack.200'}
      borderWidth={'thin'}
      boxShadow={'unset'}
      onClick={() => navigate(Routes.ReferFriend)}
    >
      <Flex mb={2}>
        <MoneyInHandIcon />
        <Text ml={4} fontSize={'lg'} fontWeight={'semibold'}>
          {formatMessage({ id: 'referral.refer.friend.title' })}
        </Text>
      </Flex>
      <Text>{formatMessage({ id: 'referral.refer.friend.content' })}</Text>
      <Text textDecoration={'underline'}>{formatMessage({ id: 'word.settIgang' })}</Text>
    </Card>
  )
}
