import { Button, Container, Flex, Heading, Input, Text } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { BackButton } from '@wanda-space/noelle'
import { resetPassword } from 'api-client/lib/routes/user'
import { GoogleButton } from 'components/auth-page-components/GoogleButton'
import { Routes } from 'consts'
import { Field, Form, Formik, type FormikHelpers } from 'formik'
import { useDocumentTitle } from 'hooks'
import { useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, Navigate } from 'react-router-dom'
import { Color } from 'styles'
import { ResetPasswordSchema } from 'utils'

const InitialValues = { email: '' }

const ForgotPassword = () => {
  const { isAuthenticated, loginSocial } = useAuth()
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (
    data: { email: string },
    { setFieldError }: FormikHelpers<typeof InitialValues>
  ) => {
    setLoading(true)
    try {
      await resetPassword({ email: data.email.toLowerCase() })
      setSuccess(true)
    } catch {
      setFieldError('email', formatMessage({ id: 'error.general' }))
      setSuccess(false)
    } finally {
      setLoading(false)
    }
  }

  const googleLogin = async () => {
    try {
      await loginSocial('google-oauth2')
    } catch (err) {
      captureException(err)
    }
  }

  useDocumentTitle(formatMessage({ id: 'word.resetPassword' }))

  if (isAuthenticated) {
    return <Navigate to={Routes.Space} />
  }

  return (
    <Container>
      <BackButton linkProps={{ as: Link, to: Routes.Login } as any} />
      <Flex direction="column" mt={2}>
        {success ? (
          <Flex justifyContent="center" direction="column" mt={2} p={4}>
            <Heading color={Color.Black} mb={4} as="h4" fontSize="xl">
              {formatMessage({
                id: 'resetPassword.weSentYouAPasswordResetLink',
              })}
            </Heading>
            <Heading color={Color.Black90} mb={8} as="h4" fontSize="lg">
              {formatMessage({ id: 'resetPassword.checkYourEmail' })}
            </Heading>
          </Flex>
        ) : (
          <Flex direction="column">
            <Heading color={Color.Black} mb={2} mt={3} as="h2">
              {formatMessage({ id: 'resetPassword.resetYourPassword' })}
            </Heading>
            <Text color={Color.Black} mb={6} fontSize="lg">
              {formatMessage({ id: 'resetPassword.enterYourEmailAddress' })}
            </Text>
            <Formik
              initialValues={InitialValues}
              onSubmit={handleSubmit}
              validationSchema={() => ResetPasswordSchema(formatMessage)}
            >
              {() => (
                <Form>
                  <Field
                    as={Input}
                    m="unset"
                    mt={3}
                    mb={3}
                    name="email"
                    placeholder={formatMessage({ id: 'word.yourEmail' })}
                  />
                  <Button
                    colorScheme="ctaBlack"
                    isLoading={loading}
                    data-testid="reset-button"
                    width="100%"
                    type="submit"
                  >
                    {formatMessage({ id: 'word.reset' })}
                  </Button>
                </Form>
              )}
            </Formik>
            <Flex direction="column" mt={4}>
              <Text color={Color.Black90} mb={10}>
                {formatMessage({ id: 'resetPassword.googleButton' })}
              </Text>
              <GoogleButton isDisabled={loading} mb="40px" onClick={googleLogin} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Container>
  )
}

export { ForgotPassword }
