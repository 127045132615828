import { Box, Container, Tag, Text } from '@chakra-ui/react'
import { Alert, ProgressIndicator, Spinner } from '@wanda-space/noelle'
import {
  OrderContext,
  OrderType,
  ServiceLevelType,
  type SupportedCities,
  type SupportedCountries,
} from '@wanda-space/types'
import { BookingHeader } from 'components/BookingHeader'
import { FlowStepRoutes } from 'components/FlowStepRoutes'
import { RequireRegistration } from 'components/RouteGuards/RequireRegistration'
import { Routes as routes } from 'consts'
import { CURRENCIES_BY_COUNTRY } from 'consts/Currency'
import { useDateLocale } from 'contexts/Intl'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { useSteps } from 'hooks/useCurrentStep'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useFlowPrices } from 'hooks/useFlowPrices'
import { useProgressIndicatorPriceSummary } from 'hooks/useProgressIndicatorPriceSummary'
import type { FlowStep } from 'interfaces/flows'
import { isEmpty } from 'ramda'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import { combinedItemsSelector } from 'reduxStore/ducks/storage/selectors'
import {
  applyDiscounts,
  resetAll,
  setEstimatedDeliveryCost,
  setPacking,
  setTaasOrderlines,
} from 'reduxStore/ducks/storage/storageFlow'
import { setMainNavFixed } from 'reduxStore/ducks/ui'
import { sortCurrentStepToLast } from 'utils/bulletPoints'
import { getItemPayloadsFromItems } from 'utils/item'
import { getTaasOrderLineForNewItemsByServiceLevelType } from 'utils/orderline'
import { add, formatPriceWrapper, getTaasAmountByServiceLevel } from 'utils/price-utils'

import { useMutation } from '@tanstack/react-query'
import { fetchDiscounts } from 'api-client/lib/routes/discount'
import { useConsumeQueryParams } from 'hooks/useQueryParam'
import { FlowDisclaimer } from 'routes/Common/FlowDisclaimer'
import { useTaas } from '../../hooks/useTaas'
import { Addons } from './Addons'
import { Address } from './Address'
import { BookingConfirmation } from './BookingConfirmation'
import { SelectMethod } from './Carrying'
import { ExistingItems } from './ExistingItems'
import { Introduction } from './Introduction'
import { ItemSelectorPage } from './ItemSelectorPage'
import { Packing } from './Packing'
import { PaymentAndSummary } from './PaymentAndSummary'
import { SelectDateAndTime } from './SelectDateAndTime'
import { SelectTransportOrWarehouseVisit } from './SelectTransportOrWarehouseVisit'
import { bulletPoints, paths } from './paths'

const deliveryOrderType = {
  [OrderType.PICK_UP]: OrderType.DELIVERY,
  [OrderType.CUSTOMER_DROP_OFF]: OrderType.CUSTOMER_COLLECTS,
}

function isStorageOrderType(
  type: OrderType
): type is OrderType.PICK_UP | OrderType.CUSTOMER_DROP_OFF {
  if (type === OrderType.PICK_UP || type === OrderType.CUSTOMER_DROP_OFF) {
    return true
  }
  return false
}

const StorageFlow = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isAuthenticating, isAuthenticated } = useAuth()
  const { formatMessage, messages } = useIntl()
  const dateLocale = useDateLocale()
  const { pathname, search: searchParams } = useLocation()
  const { country: countryCode, city } = useAppSelector((state) => state.ui)
  const estimatedTimeslotCost = useAppSelector((state) => state.ui.estimatedTimeslotCost)
  const items = useAppSelector(combinedItemsSelector)
  const flow = useAppSelector((state) => state.storageFlow)
  const { orderLines, serviceLevel, address, estimatedDeliveryCost } = flow
  const itemCount = items.length

  const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useFeatureFlags()
  const { taasPrice, packingPrice, storagePrice, onetimeAddOnPrice, recursiveAddOnPrice } =
    useFlowPrices(orderLines)

  const query = useConsumeQueryParams({ keys: ['coupon'] })
  const updateCoupon = useMutation(
    async (values: { coupon: string; country: SupportedCountries; city: SupportedCities }) => {
      const { coupon, country, city } = values
      if (!coupon || !country || !city) {
        return
      }
      const properCouponInput = coupon.toUpperCase().trim()
      const discounts = await fetchDiscounts({
        code: properCouponInput,
        city: city,
        countryCode: country,
      })
      if (discounts.length) {
        dispatch(applyDiscounts({ discounts, coupon: properCouponInput }))
      }
      return discounts
    }
  )

  useEffect(() => {
    if (query.coupon && query.coupon !== flow.coupon) {
      updateCoupon.mutate({ coupon: query.coupon, country: countryCode, city })
    }
  }, [query.coupon])

  if (!storagePrice.currency) {
    storagePrice.currency = CURRENCIES_BY_COUNTRY[countryCode]
  }
  const totalTaasAndPacking = {
    currency: taasPrice.currency,
    amount:
      taasPrice.amount +
      packingPrice.amount +
      onetimeAddOnPrice.amount +
      estimatedTimeslotCost.amount,
  }

  const { data: taasOrderLines } = useTaas({
    storageItems: getItemPayloadsFromItems(items, OrderContext.STORAGE),
    orderType: flow.orderType,
    floorNumber: address.floorNumber ?? 0,
    elevator: address.elevator,
    coupon: flow.coupon,
  })
  const {
    carryingTotalCost: deliveryCarryingTotalCost,
    curbSideTotalCost: deliveryCurbsideCost,
    firstDoorTotalCost: deliveryFirstDoorCost,
  } = useTaas({
    storageItems: getItemPayloadsFromItems(items, OrderContext.STORAGE),
    orderType: isStorageOrderType(flow.orderType)
      ? deliveryOrderType[flow.orderType]
      : OrderType.PICK_UP,
    elevator: address.elevator,
    floorNumber: address.floorNumber ?? 0,
  })

  const _serviceLevel = serviceLevel || ServiceLevelType.FIRST_DOOR

  useEffect(() => {
    if (deliveryCurbsideCost && deliveryCarryingTotalCost && taasOrderLines) {
      dispatch(
        setTaasOrderlines(
          getTaasOrderLineForNewItemsByServiceLevelType(_serviceLevel, taasOrderLines.orderline)
        )
      )

      const cost = {
        amount: Number(
          getTaasAmountByServiceLevel(_serviceLevel, {
            carryingTotalCost: deliveryCarryingTotalCost,
            curbSideTotalCost: deliveryCurbsideCost,
            firstDoorTotalCost: deliveryFirstDoorCost || '0',
          })
        ),
        currency: CURRENCIES_BY_COUNTRY[countryCode],
      }

      dispatch(setEstimatedDeliveryCost(cost))
    }
  }, [taasOrderLines, serviceLevel, deliveryCurbsideCost, deliveryCarryingTotalCost])

  useEffect(() => {
    if (packingPrice.amount !== 0) {
      const itemsIds = [...new Set(items.map((item) => item.id))]
      const packagingOrderlines = orderLines.packing.filter(
        ({ item }) => item && itemsIds.includes(item.id)
      )
      dispatch(setPacking(packagingOrderlines))
    }
  }, [orderLines.storage])

  useEffect(() => {
    dispatch(setMainNavFixed(false))

    return () => {
      dispatch(setMainNavFixed(true))
    }
  }, [isAuthenticated])

  const isAddressEmpty = () => isEmpty(address)

  const stepTitle = (key: string) =>
    formatMessage({ id: `storageFlow.steps.${flow.orderType.toLowerCase()}.${key}.title` })

  const steps: FlowStep[] = []

  if (!isAuthenticated && featureFlags?.ENABLE_FLOW_INTRODUCTION_STEP) {
    steps.push({
      element: Introduction,
      path: paths.introduction,
    })
  }

  steps.push({
    element: ItemSelectorPage,
    path: paths.selectItems,
    title: stepTitle('select-items'),
    elementProps: { appendPriceOnProductDescription: true },
    nested: { element: ExistingItems, requireLogin: true, path: paths.existingItems },
  })

  if (featureFlags?.ENABLE_SPACESHIP_WAREHOUSE_VISIT) {
    steps.push({
      element: SelectTransportOrWarehouseVisit,
      path: paths.transportOrWarehouseVisit,
      title: stepTitle('select-transport'),
      fallback: () => items.length <= 0,
    })
  }
  steps.push({
    element: SelectDateAndTime,
    path: paths.schedule,
    title: stepTitle('select-time'),
    description: formatMessage({ id: 'booking.selectTime.description' }),
    fallback: () => items.length <= 0,
  })

  if (flow.orderType === OrderType.PICK_UP) {
    steps.push(
      {
        element: Address,
        path: paths.address,
        title: stepTitle('address'),
        fallback: () => isEmpty(flow.dateAndTime),
      },
      {
        element: SelectMethod,
        path: paths.carrying,
        title: stepTitle('select-method'),
        description: formatMessage({ id: 'booking.selectMethod.description' }),
        fallback: isAddressEmpty,
      }
    )
  }

  if (flow.orderType === OrderType.CUSTOMER_DROP_OFF) {
    steps.push({
      element: Address,
      path: paths.address,
      title: stepTitle('address'),
      fallback: () => isEmpty(flow.dateAndTime),
    })
  }

  if (featureFlags?.ENABLE_PACKING_OPTION) {
    steps.push({
      element: Packing,
      path: paths.packing,
      title: stepTitle('packing'),
      fallback: isAddressEmpty,
    })
  }

  steps.push({
    element: Addons,
    path: paths.addons,
    title: stepTitle('addons'),
    description: formatMessage({ id: 'booking.addons.description' }),
    fallback: isAddressEmpty,
  })

  const paymentStep: FlowStep = {
    element: PaymentAndSummary,
    path: paths.summary,
    title: stepTitle('summary'),
    fallback: isAddressEmpty,
  }

  const disclaimerStep: FlowStep = {
    element: FlowDisclaimer,
    path: paths.disclaimer,
    title: formatMessage({ id: 'booking.disclaimer' }),
    fallback: isAddressEmpty,
  }

  if (isAuthenticated === false) {
    steps.push(paymentStep)
  }

  steps.push(
    {
      element: RequireRegistration,
      path: paths.register,
      fallback: isAddressEmpty,
      elementProps: {
        orderAddress: address,
      },
    },
    disclaimerStep,
    {
      ...paymentStep,
      requireLogin: true,
      fallback: isAddressEmpty,
    },
    {
      element: BookingConfirmation,
      path: paths.bookingConfirmation,
      requireLogin: true,
    }
  )

  const {
    currentStep,
    nextStep,
    StepGuard,
    onNextStep,
    progress,
    furthestStepReached,
    FallBackNavigate,
    isLastStep,
  } = useSteps(steps, `${routes.Storage}/`)

  const taasSummary = useProgressIndicatorPriceSummary(totalTaasAndPacking, estimatedDeliveryCost)

  if (isAuthenticating || isFeatureFlagsLoading) {
    return <Spinner />
  }

  if (FallBackNavigate) {
    return <FallBackNavigate />
  }

  if (StepGuard) {
    return <StepGuard />
  }

  const isIntroPage = currentStep.path === 'introduction'

  return (
    <>
      <BookingHeader
        title={currentStep.title}
        description={currentStep.description}
        enableFixedIndicator
        indicator={
          isIntroPage || isLastStep ? undefined : (
            <ProgressIndicator
              key={totalTaasAndPacking.amount + estimatedDeliveryCost.amount}
              pathname={pathname}
              searchParams={searchParams}
              linkComponent={Link as any}
              size="large"
              progress={progress}
              bulletPointsProps={{
                maxWidth: 'min(calc(100vw - 40px), 560px)',
                minWidth: 'min(calc(100vw - 40px), 560px)',
              }}
              taasSummary={taasSummary}
              itemSummary={`${itemCount} ${formatMessage(
                { id: 'word.itemsPlural' },
                { length: itemCount }
              )}, ${formatPriceWrapper(
                add(storagePrice, recursiveAddOnPrice),
                true,
                formatMessage
              )}`}
              bulletPoints={sortCurrentStepToLast(
                bulletPoints(formatMessage, flow, furthestStepReached, dateLocale),
                currentStep
              )}
            >
              <Text lineHeight="1.6rem">
                {formatMessage({ id: 'booking.indicator.expanded.summary' })}
                <Tag mx="1" bg="purple.200">
                  {`${
                    add(storagePrice, recursiveAddOnPrice).amount
                  } ${storagePrice.currency.toLowerCase()} ${formatMessage({
                    id: 'word.per.month',
                  })}`}
                </Tag>
                <>
                  {formatMessage({ id: 'booking.indicator.expanded.summary.taas' })}
                  <Tag bg="purple.200" mx="1">{`${totalTaasAndPacking.amount.toFixed(2)} ${
                    taasPrice.currency
                  }`}</Tag>
                </>
                .{' '}
                {formatMessage(
                  { id: 'return.from.amount' },
                  {
                    amount: (
                      <Tag bg="purple.200" mx="1">{`${estimatedDeliveryCost.amount} ${
                        estimatedDeliveryCost.currency
                          ? estimatedDeliveryCost.currency.toLowerCase()
                          : ''
                      }`}</Tag>
                    ),
                  }
                )}
              </Text>
            </ProgressIndicator>
          )
        }
        onBack={!isLastStep && !isIntroPage ? () => navigate(-1) : undefined}
        enablePostalCodeSelector={paths.selectItems === currentStep.path}
        onPostalCodeChange={() => dispatch(resetAll())}
      />
      <Container
        width={isIntroPage ? 'auto' : undefined}
        maxWidth={isIntroPage ? 'none' : undefined}
        marginStart={isIntroPage ? 0 : undefined}
        marginEnd={isIntroPage ? 0 : undefined}
        pt="0"
        pb="20"
      >
        {!!messages['storage.alert.global'] && (
          <Box maxW="560px" mx="auto" py="6">
            <Alert
              status="warning"
              id="storage-global-warning"
              text={formatMessage({ id: 'storage.alert.global' })}
            />
          </Box>
        )}
        <FlowStepRoutes
          steps={steps}
          currentStep={currentStep}
          onNextStep={onNextStep}
          flowRootPath={routes.Storage}
          nextStep={nextStep}
        />
      </Container>
    </>
  )
}
export { StorageFlow }
