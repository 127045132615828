import { Container, Spinner, VStack } from '@chakra-ui/react'
import { BackButton } from '@wanda-space/noelle'
import { FlowStepRoutes } from 'components/FlowStepRoutes'
import { Routes as routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useSteps } from 'hooks/useCurrentStep'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import type { FlowStep } from 'interfaces'
import { isEmpty } from 'ramda'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { ListingForStorage } from 'reduxStore/ducks/sell/sellFlow'
import { sellFromStorage, simpleId } from 'routes/BuySellFlow/BuySellFlow'
import { CreateListing } from 'routes/BuySellFlow/Sell/Storage/CreateListingForStorage'
import { ListingPublished } from 'routes/BuySellFlow/common/ListingPublished'
import { joinString, pathTo } from 'utils'
import { SellIntroduction } from '../../common/Introduction'
import { AddListingAddons } from '../common/AddListingAddons'
import { ListingCreateConfirmForStorage } from './ListingCreateConfirmForStorage'
import { PaymentAndSummary } from './PaymentAndSummary'
import { SelectItems } from './SelectItemsForStorage'
import { SellingType } from './SellingType'

const isListingInformationComplete = (listing: ListingForStorage) =>
  listing.formValues.name && listing.formValues.price && listing.storageItems.length

export const getPublishedListingRoute = (id: string) =>
  joinString([sellFromStorage.listingPublished, id], '/')

export const getSellItemLink = (id: string) =>
  joinString([pathTo(routes.Sell, sellFromStorage.createListing), `?itemId=${id}`])

export const selectItemsRoute = pathTo(routes.Sell, sellFromStorage.selectItems)

export const SellingFlow = (): JSX.Element => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const listingState = useAppSelector((state) => state.sellFlow)
  const [searchParams] = useSearchParams()
  const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useFeatureFlags()
  const sellFromStorageFlow = useAppSelector((state) => state.sellFlow)

  const steps: FlowStep[] = [
    ...(featureFlags?.ENABLE_FLOW_INTRODUCTION_STEP
      ? [
          {
            element: SellIntroduction,
            path: sellFromStorage.introduction,
          },
        ]
      : []),
    {
      element: SellingType,
      path: sellFromStorage.selectSellType,
    },
    {
      requireLogin: true,
      element: SelectItems,
      path: sellFromStorage.selectItems,
    },
    {
      element: CreateListing,
      path: sellFromStorage.createListing,
      requireLogin: true,
      fallback: () => isEmpty(listingState.storageItems) && isEmpty(searchParams.getAll('itemId')),
    },
    ...(featureFlags?.ENABLE_LISTING_ADDONS
      ? [
          {
            element: AddListingAddons,
            requireLogin: true,
            path: sellFromStorage.addListingAddons,
            title: formatMessage({ id: 'booking.addons' }),
            description: formatMessage({
              id: 'product.listing.addons_step.general_intro',
            }),
            elementProps: {
              isPickup: false,
            },
            fallback: () => !isListingInformationComplete(listingState),
          },
        ]
      : []),
    {
      element: ListingCreateConfirmForStorage,
      path: sellFromStorage.listingPreview,
      requireLogin: true,
      fallback: () => !isListingInformationComplete(listingState),
    },
  ]

  if (sellFromStorageFlow.addons.length) {
    steps.push({
      element: PaymentAndSummary,
      requireLogin: true,
      path: sellFromStorage.paymentStep,
      fallback: () => isEmpty(sellFromStorageFlow.addons),
    })
  }

  steps.push({
    element: ListingPublished,
    path: getPublishedListingRoute(simpleId),
    requireLogin: true,
  })

  const { isLastStep, isFirstStep, onNextStep, FallBackNavigate, currentStep, nextStep } = useSteps(
    steps,
    `${routes.Sell}/`,
    {
      useMatchPath: true,
    }
  )

  const onBack = !isLastStep && !isFirstStep ? () => navigate(-1) : undefined

  const isListingPage = [
    sellFromStorage.listingPreview,
    getPublishedListingRoute(simpleId),
  ].includes(currentStep.path)

  if (isFeatureFlagsLoading) {
    return <Spinner />
  }

  if (FallBackNavigate) {
    return <FallBackNavigate />
  }

  return (
    <>
      <Container mx={isListingPage ? 0 : undefined} py={0} alignSelf="flex-start" mb={4}>
        <VStack alignItems="flex-start" gap={2} mb={2}>
          {onBack && (
            <BackButton
              linkProps={{
                onClick: (e) => {
                  e.preventDefault()
                  onBack()
                },
              }}
            />
          )}
        </VStack>
      </Container>
      <FlowStepRoutes
        steps={steps}
        currentStep={currentStep}
        onNextStep={onNextStep}
        flowRootPath={routes.Sell}
        nextStep={nextStep}
      />
    </>
  )
}
