import { ChakraProvider, Container, Flex } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import { focusManager } from '@tanstack/react-query'
import { GeneralError, Spinner, theme } from '@wanda-space/noelle'
import { SupportedCountries } from '@wanda-space/types'
import { PrivateRoute } from 'components/RouteGuards/PrivateRoute'
import { RequireFeatureFlag } from 'components/RouteGuards/RequireFeatureFlag'
import { Routes as routes } from 'consts'
import { IntlProvider } from 'contexts/Intl'
import { StripeProvider } from 'contexts/stripe'
import { getEnv } from 'env'
import { useScrollToTop } from 'hooks'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { Layout } from 'layout/Layout'
import React, { Suspense, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { Auth0Callback } from 'routes/Auth0Callback/Auth0Callback'
import { Home } from 'routes/Home/Home'
import { Login } from 'routes/Login/Login'
import { Redirect } from 'routes/Redirect'
import { Register } from 'routes/Register/Register'
import { ForgotPassword as ResetPassword } from 'routes/ResetPassword/ForgotPassword'
import { ReturnFlow } from 'routes/ReturnFlow/ReturnFlow'
import { ServiceFromStorage } from 'routes/ServiceFlow/ServiceFromStorage/ServiceFromStorage'
import { ItemDetails } from 'routes/Space/Details/ItemDetails'
import { MyItemsView } from 'routes/Space/Item/MyItemsView'
import { Space } from 'routes/Space/Space'
import { StorageFlow as Storage } from 'routes/Storage/StorageFlow'
import { InitIntercom } from 'scripts'
import { gtm_handler } from 'tracking/gtmHandler'
import { resetHelp } from 'utils/help'

import { onWindowFocus } from './onWindowFocus'

const CollectEmail = React.lazy(async () =>
  import('routes/Register/CollectEmail').then((module) => ({
    default: module.CollectEmail,
  }))
)

const SquareMeterFlow = React.lazy(async () =>
  import('routes/SquareMeterFlow/SquareMeterFlow').then((module) => ({
    default: module.SquareMeterFlow,
  }))
)

const ServicesNext = React.lazy(async () =>
  import('routes/ServiceFlow/ServicesNext/ServicesFlowNext').then((module) => ({
    default: module.ServicesFlowNext,
  }))
)

const SelectFlow = React.lazy(async () =>
  import('routes/SelectFlow/SelectFlow').then((module) => ({
    default: module.SelectFlow,
  }))
)
const SelectStorage = React.lazy(async () =>
  import('routes/SelectFlow/SelectStorage').then((module) => ({
    default: module.SelectStorage,
  }))
)

const Sell = React.lazy(async () =>
  import('routes/BuySellFlow/Sell/Storage/SellingFlowForStorage').then((module) => ({
    default: module.SellingFlow,
  }))
)

const BuyFlow = React.lazy(async () =>
  import('routes/BuySellFlow/Buy/BuyingFlow').then((module) => ({
    default: module.BuyingFlow,
  }))
)

const BuyAdvertisementRedirect = () => {
  const { simpleId } = useParams()

  useEffect(() => {
    if (simpleId) {
      window.location.replace(`https://wanda.space/market/listing/${simpleId}`)
    } else {
      window.location.replace('https://wanda.space/market')
    }
  }, [simpleId])

  return <Spinner />
}

const SellWithPickup = React.lazy(async () =>
  import('routes/BuySellFlow/Sell/Pickup/SellingWithPickupFlow').then((module) => ({
    default: module.SellingWithPickUpFlow,
  }))
)

const PackagingFlow = React.lazy(async () =>
  import('routes/PackagingFlow/PackagingFlow').then((module) => ({
    default: module.PackagingFlow,
  }))
)

const BidFlow = React.lazy(async () =>
  import('routes/BuySellFlow/Bid/BidFlow').then((module) => ({
    default: module.BidFlow,
  }))
)

const Bid = React.lazy(async () =>
  import('routes/BuySellFlow/Bid/Bid').then((module) => ({
    default: module.Bid,
  }))
)

const MarketRedirect = () => {
  useEffect(() => {
    window.location.replace('https://wanda.space/market')
  }, [])

  return <Spinner />
}

const ListingNotFound = React.lazy(async () =>
  import('routes/BuySellFlow/common/ListingNotFound').then((module) => ({
    default: module.ListingNotFound,
  }))
)

// Referral Related Routes
const ReferralSignup = React.lazy(async () =>
  import('routes/Referral/ReferalSignup').then((module) => ({
    default: module.ReferralSignup,
  }))
)
const ReferFriend = React.lazy(async () =>
  import('routes/Referral/ReferFriend').then((module) => ({
    default: module.ReferFriend,
  }))
)
// End of Referral Related Routes

const NoImageItemView = React.lazy(async () =>
  import('routes/Space/Item/NoImageItemView').then((module) => ({
    default: module.NoImageItemView,
  }))
)

const SharingInfo = React.lazy(async () =>
  import('routes/SharingInfo/SharingInfo').then((module) => ({
    default: module.SharingInfo,
  }))
)

// Settings and Child Routes
const Settings = React.lazy(async () =>
  import('routes/Settings/Settings').then((module) => ({
    default: module.Settings,
  }))
)

const ChangePassword = React.lazy(async () =>
  import('routes/Settings/Password/ChangePassword').then((module) => ({
    default: module.ChangePassword,
  }))
)

const Partnerships = React.lazy(async () =>
  import('routes/Settings/Partnerships/Partnerships').then((module) => ({
    default: module.Partnerships,
  }))
)

const CreditTransactions = React.lazy(async () =>
  import('routes/Settings/Referrals/CreditTransactions').then((module) => ({
    default: module.CreditTransactions,
  }))
)

const PackagingOrders = React.lazy(async () =>
  import('routes/Settings/Orders/PackagingOrders').then((module) => ({
    default: module.PackagingOrders,
  }))
)

const ObosMembershipPage = React.lazy(async () =>
  import('routes/Settings/ObosMembership/ObosMembershipPage').then((module) => ({
    default: module.ObosMembershipPage,
  }))
)

const PriceIncrease = React.lazy(async () =>
  import('routes/Settings/Payments/PriceIncrease/PriceIncrease').then((module) => ({
    default: module.PriceIncrease,
  }))
)

const EditAddress = React.lazy(async () =>
  import('routes/Settings/Address/EditAddress').then((module) => ({
    default: module.EditAddress,
  }))
)

const MyOrders = React.lazy(async () =>
  import('routes/MyOrders/MyOrders').then((module) => ({
    default: module.MyOrders,
  }))
)

// Lists of Orders
const SalesOrders = React.lazy(async () =>
  import('routes/Settings/Orders/SalesOrders').then((module) => ({
    default: module.SalesOrders,
  }))
)
const PurchaseOrders = React.lazy(async () =>
  import('routes/Settings/Orders/PurchaseOrders').then((module) => ({
    default: module.PurchaseOrders,
  }))
)
const TransportOrders = React.lazy(async () =>
  import('routes/Settings/Orders/TransportOrders').then((module) => ({
    default: module.TransportOrders,
  }))
)
const ServiceOrders = React.lazy(async () =>
  import('routes/Settings/Orders/ServiceOrders').then((module) => ({
    default: module.ServiceOrders,
  }))
)
// End of Lists of Orders

// Payment Related Items
const Billing = React.lazy(async () =>
  import('routes/Settings/Payments/Billing/Billing').then((module) => ({
    default: module.Billing,
  }))
)
const Invoices = React.lazy(async () =>
  import('routes/Settings/Payments/Invoices/Invoices').then((module) => ({
    default: module.Invoices,
  }))
)
const MySubscription = React.lazy(async () =>
  import('routes/Settings/Payments/MySubscription/MySubscription').then((module) => ({
    default: module.MySubscription,
  }))
)
const MyTransfers = React.lazy(async () =>
  import('routes/Settings/Payments/MyTransfers/MyTransfers').then((module) => ({
    default: module.MyTransfers,
  }))
)
// End Payment Related Items

// Logistic Order Edits
const EditOrderDetails = React.lazy(async () =>
  import('routes/Settings/Payments/Orders/EditOrderDetails').then((module) => ({
    default: module.EditOrderDetails,
  }))
)
const EditOrderAddress = React.lazy(async () =>
  import('routes/Settings/Payments/Orders/EditOrderViews/EditOrderAddress').then((module) => ({
    default: module.EditOrderAddress,
  }))
)
const EditOrderCarrying = React.lazy(async () =>
  import('routes/Settings/Payments/Orders/EditOrderViews/EditOrderCarrying').then((module) => ({
    default: module.EditOrderCarrying,
  }))
)
const EditOrderAddPickupItems = React.lazy(async () =>
  import('routes/Settings/Payments/Orders/EditOrderViews/EditOrderAddPickupItems').then(
    (module) => ({ default: module.EditOrderAddPickupItems })
  )
)
const EditOrderAddDeliveryItems = React.lazy(async () =>
  import('routes/Settings/Payments/Orders/EditOrderViews/EditOrderAddDeliveryItems').then(
    (module) => ({ default: module.EditOrderAddDeliveryItems })
  )
)
const EditOrderPacking = React.lazy(async () =>
  import('routes/Settings/Payments/Orders/EditOrderViews/EditOrderPacking').then((module) => ({
    default: module.EditOrderPacking,
  }))
)
const EditOrderTime = React.lazy(async () =>
  import('routes/Settings/Payments/Orders/EditOrderViews/EditOrderTime').then((module) => ({
    default: module.EditOrderTime,
  }))
)
// End of Logistic Order Edits
const EditServiceOrder = React.lazy(async () =>
  import('routes/Settings/ServiceOrders/EditServiceOrder').then((module) => ({
    default: module.EditServiceOrder,
  }))
)
// Service Order Edit

// End of Service Order Edit

// End of Settings and Child Routes

const SelectAccount = React.lazy(async () =>
  import('routes/Settings/SelectAccount/SelectAccount').then((module) => ({
    default: module.SelectAccount,
  }))
)

const App = () => {
  // https://tanstack.com/query/v4/docs/guides/window-focus-refetching#ignoring-iframe-focus-events
  focusManager.setEventListener(onWindowFocus)
  resetHelp()
  useScrollToTop()
  const { isAuthenticated, renewSession } = useAuth()

  useEffect(() => {
    renewSession()
    /** @see {@link MP_handler} for mixpanel (global handler, used by redux middleware and useTracker hook) */
    /** @see {@link gtmHandler} for GTM (global handler, used by redux middleware and useTracker hook) */

    // This is just temporary - to assure that TagManager is initialized early on
    // We do not need this after we have implemented hooks to use the GTM handler
    gtm_handler.get_doNotTrack()
    /*
    TagManager.initialize({
      gtmId: getEnv('GOOGLE_TAG_MANAGER_ID') || process.env.GOOGLE_TAG_MANAGER_ID || '',
    })*/
  }, [])

  return (
    <ErrorBoundary
      onError={() => sessionStorage.clear()}
      fallback={() => (
        <GeneralError
          buttonText={isAuthenticated ? 'Go to your wanda space' : 'Go to homepage'}
          onClick={() => {
            // this is location.replace instead of history.replace
            // since we want to make the browser reload the app
            window.location.replace(isAuthenticated ? '/space' : 'https://wanda.space')
          }}
        />
      )}
    >
      <StripeProvider>
        <InitIntercom />
        <Suspense
          fallback={
            <Container height="100%">
              <Flex
                mt="4rem"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="calc(100% - 4rem)"
              >
                <Spinner />
              </Flex>
            </Container>
          }
        >
          <IntlProvider>
            <Layout>
              <AllRoutes />
            </Layout>
          </IntlProvider>
        </Suspense>
      </StripeProvider>
    </ErrorBoundary>
  )
}

const AllRoutes = () => {
  const country = useAppSelector((state) => state.ui.country)

  return (
    <Routes>
      <Route element={<Home />} path={routes.Home} />
      <Route element={<Redirect />} path={routes.Redirect} />
      <Route element={<PrivateRoute />}>
        <Route key="Space" element={<Space />} path={routes.Space} />
        <Route key="MySubscription" element={<MySubscription />} path={routes.MySubscription} />
        <Route
          key="ItemDetails(':id')"
          element={<ItemDetails />}
          path={routes.ItemDetails(':id')}
        />
        <Route key="NoImageItemView" element={<NoImageItemView />} path={routes.NoImageItemView} />
        <Route key="Settings" element={<Settings />} path={routes.Settings} />
        <Route key="EditAddress" element={<EditAddress />} path={routes.EditAddress} />
        <Route key="ObosMembership" element={<ObosMembershipPage />} path={routes.ObosMembership} />
        <Route key="PriceIncrease" element={<PriceIncrease />} path={routes.PriceIncrease} />

        <Route element={<RequireFeatureFlag featureFlag="ENABLE_SHARING_UI" />}>
          <Route key="SharingInfo" element={<SharingInfo />} path={routes.SharingInfo(':id')} />
        </Route>
        <Route key="ChangePassword" element={<ChangePassword />} path={routes.ChangePassword} />
        <Route key="Invoices" element={<Invoices />} path={routes.Invoices} />
        <Route key="MyTransfers" element={<MyTransfers />} path={routes.MyTransfers} />
        <Route key="Billing" element={<Billing />} path={routes.Billing} />
        <Route key="Orders()" element={<MyOrders />} path={routes.Orders} />
        <Route key="PurchaseOrder" element={<PurchaseOrders />} path={routes.PurchaseOrders} />
        <Route key="SalesOrder" element={<SalesOrders />} path={routes.SalesOrders} />
        <Route element={<RequireFeatureFlag featureFlag="ENABLE_REFERRAL" />}>
          <Route key="ReferFriend" element={<ReferFriend />} path={routes.ReferFriend} />
          <Route key="Referrals" element={<CreditTransactions />} path={routes.Referrals} />
        </Route>
        <Route
          element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_SERVICE_FROM_STORAGE" />}
        >
          <Route
            key="ServiceFromStorage"
            element={<ServiceFromStorage />}
            path={`${routes.ServiceFromStorage}/*`}
          />
        </Route>
        <Route
          key="EditOrderAddress"
          element={<EditOrderAddress />}
          path={routes.EditOrderAddress(':id')}
        />
        <Route
          key="EditOrderDetails"
          element={<EditOrderDetails />}
          path={routes.EditOrderDetails(':id')}
        />
        <Route
          key="EditOrderCarrying"
          element={<EditOrderCarrying />}
          path={routes.EditOrderCarrying(':id')}
        />
        <Route key="EditOrderTime" element={<EditOrderTime />} path={routes.EditOrderTime(':id')} />
        <Route
          key="EditOrderAddPickupItems"
          element={<EditOrderAddPickupItems />}
          path={routes.EditOrderAddPickupItems(':id')}
        />
        <Route
          key="EditOrderAddItems"
          element={<EditOrderAddDeliveryItems />}
          path={routes.EditOrderAddDeliveryItems(':id')}
        />
        <Route element={<RequireFeatureFlag featureFlag="ENABLE_PACKING_OPTION" />}>
          <Route
            key="EditOrderPacking"
            element={<EditOrderPacking />}
            path={routes.EditOrderPacking(':id')}
          />
        </Route>
        <Route
          key="EditServiceOrder"
          element={<EditServiceOrder />}
          path={routes.EditServiceOrder(':id')}
        />
        <Route key="myItemsView" element={<MyItemsView />} path={routes.MyItemsView} />
        <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_RETURN" />}>
          <Route key="return-flow" element={<ReturnFlow />} path={`${routes.ReturnFlow}/*`} />
        </Route>
        <Route key="ServiceOrders" element={<ServiceOrders />} path={routes.ServiceOrders} />
        <Route key="TransportOrders" element={<TransportOrders />} path={routes.TransportOrders} />
        <Route key="PackagingOrders" element={<PackagingOrders />} path={routes.PackagingOrders} />
        <Route key="view-bid" path={routes.ViewBid(':listingId', ':bidId')} element={<Bid />} />
        {country === SupportedCountries.NO && [
          <Route key="Partnerships" element={<Partnerships />} path={routes.Partnerships} />,
        ]}
        <Route key="select-account" element={<SelectAccount />} path={routes.SelectAccounts} />
      </Route>
      {/* end private routes */}

      <Route
        key="ReferredUserSignup"
        element={<ReferralSignup />}
        path={routes.ReferredUserSigup(':country', '/:id')}
      />

      <Route element={<Login />} path={routes.Login} />
      <Route element={<Auth0Callback />} path={routes.LoginCallback} />
      <Route element={<Register />} path={routes.Register} />
      <Route element={<ResetPassword />} path={routes.ResetPassword} />

      <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_PACKAGING" />}>
        <Route key="shop" element={<PackagingFlow />} path={`${routes.Shop}/*`} />
      </Route>

      <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_SELL" />}>
        <Route key="sell" element={<Sell />} path={`${routes.Sell}/*`} />
        <Route
          key="sell-with-pickup"
          element={<SellWithPickup />}
          path={`${routes.SellWithPickup}/*`}
        />
      </Route>
      <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_BUY" />}>
        <Route key="buy">
          <Route
            key="buy-advertisement"
            path={`${routes.Buy}/listing/:simpleId`}
            element={<BuyAdvertisementRedirect />}
          />

          <Route key="buy-flow-steps" element={<BuyFlow />} path={`${routes.Buy}/*`} />
        </Route>

        <Route key="bid-flow-steps" path={`${routes.Bid}/*`} element={<BidFlow />} />
      </Route>
      <Route key="listing-not-found" element={<ListingNotFound />} path={routes.ListingNotFound} />

      <Route key="flow" element={<SelectFlow />} path={routes.SelectFlow} />

      <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_STORAGE" />}>
        <Route key="storage" element={<SelectStorage />} path={routes.SelectStorage} />
      </Route>

      <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_STORAGE" />}>
        <Route
          element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_STORAGE_PER_ITEM" />}
        >
          <Route key="storage" element={<Storage />} path={`${routes.Storage}/*`} />
        </Route>
      </Route>

      <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_STORAGE" />}>
        <Route key="storage" element={<SquareMeterFlow />} path={`${routes.SquareMeterFlow}/*`} />
      </Route>

      <Route key="market" element={<MarketRedirect />} path={routes.Market} />

      <Route element={<RequireFeatureFlag featureFlag="ENABLE_SPACESHIP_FLOW_SERVICES" />}>
        <Route key="services-next" element={<ServicesNext />} path={`${routes.ServicesNext}/*`} />
      </Route>

      <Route path={routes.CollectEmail} element={<CollectEmail />} />
      <Route path="*" element={<Navigate to={routes.SelectFlow} />} />
    </Routes>
  )
}

export { App }
